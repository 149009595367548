export default {
    data() {
        return {
        }
    },
    mounted() {
        //监听滚动条
        window.addEventListener('scroll', this.removeFixed, true)
    },
    destroyed() {
        //销毁监听
        window.removeEventListener("scroll", this.removeFixed, true)
    },
    methods: {
        removeFixed() {
            if(document.querySelector('.car-pay')){
                this.$nextTick(() => {
                    if (document.documentElement.scrollTop + window.innerHeight >= this.offsetTop) {
                        // console.log(this.offsetTop, 'offsetTop', this.shopHeight, 'shopHeight');
                        // console.log(document.documentElement.scrollTop, 'document.documentElement.scrollTop', window.innerHeight, 'window.innerHeight');
                        document.querySelector('.car-pay').style.position = 'relative'
                        document.querySelector('.car-pay').style.boxShadow = '0px 0px 0px 0px rgba(0, 0, 0, 0)'
                    } else {
                        
                        document.querySelector('.car-pay').style.position = 'fixed'
                        document.querySelector('.car-pay').style.bottom = '0'
                        document.querySelector('.car-pay').style.boxShadow = '0px -10px 10px 0px rgba(0, 0, 0, 0.1)'
                    }
                })
            }
        },
    }
}